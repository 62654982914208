import React, { useEffect, useState } from 'react'
import ProductCard from '../components/ProductCard'
import { ToastContainer } from 'react-toastify'
import Header from '../layout/Header'
import { useGlobalContext } from '../contexts/GlobalContext'
import Footer from '../layout/Footer'
import { useLocation, useSearchParams, useNavigate, Link } from 'react-router-dom'
import { useInventoryContext } from '../contexts/InventoryContext'
import logo from '../assets/images/main-logo.svg';
import books from '../assets/images/book.svg'
import maps from '../assets/images/maps.svg'
import note from '../assets/images/Notepad.svg'
import Loading from '../components/loading/Loading'
const MarketPlace = () => {
  const { img_url, api_url, convertToPlain, categoryData, isLoading } = useGlobalContext();
  const { getPageInventory, pageData, pageInventory, getLastCategories, lastCategories, searchParams, setSearchParams, getVendors, vendors, setVendors, setLastCategories, searchKeyword, setSearchKeyword } = useInventoryContext();
  const [searchVendors, setSearchVendors] = useState([]);
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    // setLastCategories([])
  }, [pathname])

  // alert(searchKeyword);
  const [marketData, setMarketData] = useState({
    search_place: '',
    vendor: ''
  });
  const location = useLocation();
  const url = `${api_url}/pageInventory`;


  const [marketErrors, setMarketErrors] = useState({});

  // const cat_id = location.state?.cat_id
  // const subcat_id = location.state?.subcat_id;
  // const subcat2_id = location.state?.subcat2_id
  const [cat_id, setCat_id] = useState(location.state?.cat_id || null);
  const [subcat_id, setSubcat_id] = useState(location.state?.subcat_id || null);
  const [subcat2_id, setSubcat2_id] = useState(location.state?.subcat2_id || null);
  useEffect(() => {
    // getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'), cat_id, subcat_id, subcat2_id, null)
    getVendors();
  }, [subcat2_id, setSubcat2_id]);
  useEffect(() => {
    if (searchParams.get('search'))
      getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))

  }, [searchParams]);

  useEffect(() => {
    setLastCategories([])
    // getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
  }, [location]);



  //Clear Fiters
  const clearFilters = () => {
    if (searchParams.get('search')) {
      searchParams.delete('search')
    }
    if (searchParams.get('sort')) {
      searchParams.delete('sort')
    }
    setSearchParams(searchParams)

    getPageInventory(`${api_url}/pageInventory`)
  }
  const [venderfound, setVenderfound] = useState(true)
  //handlePlaceChange
  const handlePlaceChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    setVenderfound(true)
    const filtered_vendors = vendors.filter((vendor) => {
      return (
        vendor.state?.toLowerCase().includes(e.target.value.toLowerCase())
        ||
        vendor.city?.toLowerCase().includes(e.target.value.toLowerCase())
        ||
        vendor.distt?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    });
    console.log(searchVendors)
    if (e.target.value === '') {
      setSearchVendors([])
    }
    else {
      if (filtered_vendors.length > 0)
        setSearchVendors(filtered_vendors)
      else {
        setVenderfound(false)
        setSearchVendors([])
      }

    }
  }

  //Sort By Price
  const SortByPrice = (order) => {
    searchParams.set('sort', order);
    setSearchParams(searchParams)
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
  }

  const handleNextPage = () => {
    console.log(Activecat2, 'Activecat2')
    if (Activecat2) {

      getPageInventory(pageData.next_page_url, searchParams.get('search'), null, null, null, Activecat2)
      window.scrollTo(0, 0)
    } else if (!subcat2_id) {
      getPageInventory(pageData.next_page_url, searchParams.get('search'), null, null, null, null)
      window.scrollTo(0, 0)
    }
    else {
      getPageInventory(pageData.next_page_url, searchParams.get('search'), cat_id, subcat_id, subcat2_id, null)
      window.scrollTo(0, 0)
    }
  }

  const handlePreviousPage = () => {
    if (!subcat2_id) {
      getPageInventory(pageData.prev_page_url, searchParams.get('search'), null, null, null)
      window.scrollTo(0, 0)
    }
    else {
      getPageInventory(pageData.prev_page_url, searchParams.get('search'), cat_id, subcat_id, subcat2_id)
      window.scrollTo(0, 0)
    }
  }

  const handleLastCategory = (subcat3_id) => {
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'), null, null, null, subcat3_id)
  }
  const handleCategory = (cat_id, subcat_id, subcat2_id) => {
    getPageInventory(url, null, cat_id, subcat_id, subcat2_id);
    getLastCategories(cat_id, subcat_id, subcat2_id)
    navigate('/marketplace', { state: { cat_id: cat_id, subcat_id: subcat_id, subcat2_id: subcat2_id } })
  }

  const MarketValidation = () => {
    const errors = {};
    const { search_place, vendor } = marketData;

    if (!search_place) {
      errors.search_place = 'Please select city name';
    }
    if (!vendor || vendor === '') {
      errors.vendor = 'Please select vendor';
    }
    return errors;
  }

  //handleVendorSearch
  const handleVendorSearch = (e) => {
    e.preventDefault();
    let errors = MarketValidation();
    setMarketErrors(errors);

    if (Object.keys(errors).length === 0) {
      navigate('/vendorshop', { state: { 'vendor_id': marketData.vendor } })
    }
  }
  const [selectedSubcategories, setSelectedSubcategories] = useState(null);
  const [selectedcategories, setSelectedcategories] = useState(cat_id || null);


  const handleCardClick = (subcat, cat_id) => {

    setSelectedcategories(cat_id)

    setSelectedSubcategories(subcat);
    // setLastCategories([]);
  };
  //handleVendorChange
  const handleVendorChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const handleChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const [openSubcatId, setOpenSubcatId] = useState(null);
  const [currentSubcat2, setCurrentSubcat2] = useState(null || subcat2_id);
  const [SelectActivesubcat, setSelectActivesubcat] = useState(null);
  const [Activecat2, setActiveSubcat2] = useState(null);
  const toggleSubcat = (subcat_id, subcat2) => {
    setOpenSubcatId(openSubcatId === subcat_id ? null : subcat_id);
    if (openSubcatId !== subcat_id) {
      setCurrentSubcat2(subcat2); // Set subcat2 when the subcat is opened
    } else {
      setCurrentSubcat2([]); // Clear subcat2 if the subcat is closed
    }
  };
  const [catName, setCatName] = useState(null)
  const [subcatName, setsubCatName] = useState(null)
  const handleSubcatClick = (subcat_id, subcat2) => {

    setCurrentSubcat2(subcat2);
    setOpenSubcatId(subcat_id);
    setSubcat_id(subcat_id)

  };
  const handleSearch = () => {
    // getPageInventory(url);
    // if (location.pathname !== '/marketplace') {
    // navigate('/marketplace')
    navigate({
      pathname: '/marketplace',
      search: `?search=${searchKeyword}`,
    });
    // }
    // navigate('/marketplace')
    // navigate('/marketplace', { state: {'searchKeyword' : searchKeyword} });
  }
  const [setsubsubcatName, setSetsubsubcatName] = useState(null)
  const handleMaincategory = (subcat, cat_id) => {
    handleCardClick(subcat, cat_id);
    setCat_id(cat_id);
    setOpenSubcatId(null)
    setLastCategories([])
    // clearFilters()
  }
  const handleSubcat2 = (cat_id, sub, subsub) => {
    getLastCategories(cat_id, sub.subcat_id, subsub.subcat2_id); setSubcat2_id(subsub.subcat2_id); setSelectActivesubcat(subsub.subcat2_id)
  }
  useEffect(() => {
    setCatName(null)
    // setsubCatName(null)
    // setSetsubsubcatName(null)
    categoryData.forEach((main) => {
      const { cat_name, subcat } = main;
      if (cat_id === main.cat_id) {
        // console.log(`Main Category: ${cat_name}, ID: ${cat_id}`, main);
        handleMaincategory(subcat, cat_id);
        setCatName(cat_name)

        subcat.forEach((sub) => {
          const { subcat_name, subcat2 } = sub;
          if (subcat_id === sub.subcat_id) {
            // console.log(subcat2, 'sub2')
            handleSubcatClick(subcat_id, subcat2)
            setsubCatName(subcat_name)
            // handleSubcatClick(subcat.subcat_id, subcat.subcat2)
            // // console.log(sub.subcat_id, 'item1')
            // // setCurrentSubcat2(subcat2)
            // // setSelectActivesubcat(subcat2)
            subcat2.forEach((subsub) => {
              const { subcat_id } = subsub;
              if (subcat2_id === subsub.subcat2_id) {
                // handleSubcat2(cat_id, subcat, subcat2)
                setSubcat2_id(subcat2_id); setSelectActivesubcat(subcat2_id)
                setSetsubsubcatName(subsub.subcat2_name)
                // getLastCategories(selectedcategories, subcat.subcat_id, subsub.subcat2_id); setSubcat2_id(subsub.subcat2_id); setSelectActivesubcat(subsub.subcat2_id)
              }
            });
          }
        });
      }
    });
  }, [cat_id, subcat_id, subcat2_id]);

  return (
    <>
      {isLoading ?
        <Loading /> : null
      }

      {/* {console.log(searchParams.get('search'))    } */}
      <Header />
      <main id='main ' className='mt-4 pt-5 marketplace space-top'>
        <div className="py-md-0 d-md-none d-block py-5"></div>
        {/* {JSON.stringify(subcat2_id)},
        {JSON.stringify(subcat_id)},
        {JSON.stringify(cat_id)}
        {console.log(categoryData,'lfl')} */}
        {/* 
        {JSON.stringify(selectedcategories)}
        {JSON.stringify(selectedSubcategories)} */}
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-2 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
          <div className="container d-md-none d-block">

            <div className="input-group mb-3 shadow-md rounded-3" style={{ height: '53px' }}>
              <input type="text" className="form-control  border-end-0" onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }} value={searchKeyword !== null ? searchKeyword : ''} placeholder="Search Items" />
              <div className="input-group-append">
                <span className="input-group-text search-icon  h-100 cursor-pointer" onClick={() => handleSearch()}>
                  <i className="fa-solid fa-magnifying-glass p-2 bg-dark text-light rounded-3" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-----------------------------------Banner Starts---------------------------------> */}
        <section id="banner" className=''>
          <div id="carouselExampleCaptions" className="carousel overflow-hidden" style={{ maxHeight: ' 400px' }}>
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className='banner_img_contain'>
                  <img src={require('../assets/images/marketback.png')} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-----------------------------------Banner Ends-----------------------------------> */}


        {/* <!-----------------------------------Form Starts-----------------------------------> */}
        <section className="py-1 position-absolute w-100 z-2">
          <div className="container banner-search ">
            <form>
              <div className="row g-md-2 g-3 justify-content-center">
                <div className="col-md-4 col-sm-6">

                  <div className="d-flex flex-column-reverse h-100">
                    <input type="text" className="b-shadow shadow-btm border-dark form-control h-100" onChange={(e) => handlePlaceChange(e)} name='search_place' value={marketData.search_place} id="city" placeholder="Enter state, district, city to get Vendor" />


                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="d-flex">
                    <select className="b-shadow shadow-btm border-dark form-select py-3" onChange={handleVendorChange} name='vendor' >
                      {venderfound ? <option>Select Vendor </option> : <option>Vendor Not found</option>}

                      {
                        searchVendors.map((vendor, i) => {
                          return (
                            <option value={vendor.unique_id}>{vendor.username}</option>
                          )
                        })
                      }
                    </select>
                    <button className="b-shadow shadow-btm border-dark btn btn-dark p-3 ms-4" onClick={handleVendorSearch}><i className="ti ti-search"></i></button>
                  </div>
                  <div className="text-danger">{marketErrors.vendor}</div>
                </div>
              </div>
            </form>
          </div>
        </section>
        {/* <!-----------------------------------Form Ends-------------------------------------> */}
        <section>
          <div className="container">
            <div className="d-flex gap-3 fs-6 fw-bold justify-content-start  mt-md-3 mt-5 pt-md-1 pt-5">
              {/* <span>Travel Accessories &gt; Bag Packs  &gt; Junior</span> */}
              <span>Marketplace {catName ? <span>&gt; {catName}</span> : ''}    </span>
              {/* {subcatName ? <span>&gt; {subcatName}</span> : ''} {setsubsubcatName ? <span>&gt; {setsubsubcatName}</span> : ''}  */}
              {/* {JSON.stringify(setsubsubcatName)} */}
            </div>
          </div>
        </section>
        {/* {JSON.stringify(selectedSubcategories)} */}
        {/* <section id="shop-title" className="py-5">

          <div className="container">
        
            <div className="row text-center h-100">
           

            </div>
          </div>
        </section> */}
        <section id="shop-title" className="pt-5">
          <div className="container">
            <div className="row g-3 h-100 ">
              {categoryData.length !== 0 &&
                categoryData.map((ctg, i) => {
                  const { cat_name, cat_id, subcat, cat_img, folder } = ctg;
                  let imageToRender;
                  switch (i) {
                    case 0:
                      imageToRender = <img src={`${img_url}/${folder}/${cat_img}`} alt="" className='w-100 categorycardimg h-100' />;
                      break;

                    case 1:
                      imageToRender = <img src={`${img_url}/${folder}/${cat_img}`} alt="" className='w-100 categorycardimg h-100' />;
                      break;

                    case 2:
                      imageToRender = <img src={`${img_url}/${folder}/${cat_img}`} alt="" className='w-100 categorycardimg h-100' />;
                      break;

                    default:
                      imageToRender = null;
                      break;

                  }
                  return (
                    <div className="col-4 h-100">
                      <Link to="" className=" btn rounded-5 w-100 p-0 h-100" key={cat_id} onClick={() => { setCat_id(cat_id); clearFilters(); setActiveSubcat2(null); setSubcat2_id(null) }}>
                        <div className="card">
                          <div className="bg  d-flex flex-md-row flex-column gap-2 align-items-center">
                            <div className=' top-catagory-card-img mx-md-0 mx-auto overflow-hidden h-100'>
                              {imageToRender}
                            </div>
                            <button
                              className="nav-link fw-bold  text-md-start "
                              href="#"

                            >
                              {cat_name}
                            </button>


                          </div>
                          <div className="blob"></div>

                        </div>
                      </Link>
                    </div>)
                })}

            </div>
            {/* {subcat_id} */}
            <div>
              {selectedSubcategories && (
                <div className="d-flex border border-2 border-dark rounded-3 mt-3 align-items-center">
                  <div className="selected-subcategories py-2">
                    {selectedSubcategories.map((subcat) => (
                      <div
                        className={`btn border border-2 market-btn ms-2 mb-2 ${openSubcatId === subcat.subcat_id ? 'text-danger border-danger active' : 'border-dark'}`}
                        key={subcat?.subcat_id}
                        onClick={() => { setSubcat_id(subcat?.subcat_id); }}
                      >
                        {subcat?.subcat_name}

                      </div>
                    ))}

                  </div>
                </div>
              )}
              {/* {JSON.stringify(currentSubcat2)} */}
              {selectedSubcategories && (
                selectedSubcategories.map((subcat) => (
                  <div key={subcat.subcat_id}>
                    {openSubcatId === subcat.subcat_id && currentSubcat2.length > 0 && (
                      <div className="d-flex border border-2 mt-3 border-dark rounded-3 align-items-center position-relative">
                        <div className="selected-subcategories py-2 px-3">
                          <h5 className='text-danger'>
                            {subcat?.subcat_name}
                          </h5>
                          <div className='d-flex gap-2 flex-wrap'>

                            {currentSubcat2.map((subcat2Item) => (
                              <div className={`btn border border-2 ms-2 mb-2 market-btn ${subcat2Item.subcat2_id === SelectActivesubcat ? 'text-danger  border-danger active' : ' border-dark'}`} key={subcat2Item.subcat2_id} onClick={() => { getLastCategories(selectedcategories, subcat.subcat_id, subcat2Item.subcat2_id); setSubcat2_id(subcat2Item.subcat2_id); setSelectActivesubcat(subcat2Item.subcat2_id); setActiveSubcat2(null) }} >{subcat2Item.subcat2_name}</div>
                            ))}

                          </div>
                        </div>
                        <button className='position-absolute end-0 top-0 btn btn-sm btn-dark p-0 px-1' onClick={() => { getLastCategories(null, null, null); clearFilters(); setOpenSubcatId(null); setSubcat2_id(null); setSubcat_id(null) }}><i class="ti ti-x"></i></button>
                      </div>
                    )}
                  </div>
                ))
              )}
              {/* {console.log(lastCategories)} */}

              {
                lastCategories.length !== 0 ?
                  <div className=" border border-dark rounded-3 mt-3 border-bottom-3 position-relative">

                    <h5 className='text-danger ps-2'>
                      {setsubsubcatName && setsubsubcatName}
                    </h5>
                    <div className=' d-flex flex-wrap '>

                      {
                        lastCategories.map((cat, i) => {
                          const { id, title } = cat;
                          return (
                            <div className=' border-gray  py-1 '>
                              <span className={`btn border border-2 ms-2 mb-2 market-btn ${Activecat2 === id ? 'text-danger  border-danger active' : 'border-dark'} `} onClick={() => { handleLastCategory(id); setActiveSubcat2(id); }}>{title}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                    <button className='position-absolute end-0 top-0 btn btn-sm btn-dark p-0 px-1 subsubcat2' onClick={() => { setLastCategories([]); setActiveSubcat2(null); clearFilters(); setSubcat2_id(null); console.log('clicked') }}><i class="ti ti-x "></i></button>
                  </div> :
                  (
                    subcat2_id !== null ? (
                      <div className="border border-dark rounded-3 mt-3 border-bottom-3 position-relative">
                        <h5 className='text-danger ps-2'>
                          {setsubsubcatName ? setsubsubcatName : ''}
                        </h5>
                        <div className=''>
                          <div className='border-gray py-1'>
                            <h6 className='text-center text-danger'>No category Found</h6>
                          </div>
                        </div>
                        <button className='position-absolute end-0 top-0 btn btn-sm btn-dark p-0 px-1 subsubcat2'
                          onClick={() => { setLastCategories([]); setActiveSubcat2(null); setSubcat2_id(null); clearFilters(); console.log('clicked') }}>
                          <i className="ti ti-x "></i>
                        </button>
                      </div>
                    ) : null
                  )

              }

            </div>
          </div>
        </section>

        <section id="category-product" className="pt-5">

          <div className="container">
            {/* {subcat2_id}
          {subcat_id}
          {cat_id} */}
            <div className="row">



              {
                pageInventory.length !== 0 ?
                  <div className="row prod g-3 ">
                    {
                      pageInventory &&
                      pageInventory.map((card, i) => {
                        const { id, product_id,reviews, last_cat_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
                        return (
                          <div key={i} className="col-lg-3 col-md-6 col-6">
                            <ProductCard
                              id={id}
                              key={i}
                              product_id={product_id}
                              image={`${img_url}/${folder}/${image}`}
                              title={product_name}
                              mrp={mrp}
                              reviews={reviews}
                              discounted_price={discounted_price}
                              shipping_charges={shipping_charges}
                              type={type}
                              text={convertToPlain(description)}
                              Card={card}
                              itemExistInCart={itemExistInCart}
                              itemExistInWishlist={itemExistInWishlist}
                              last_cat_id={last_cat_id}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                  :
                  <div className='h4 text-danger text-center'>No Item Found</div>
              }


            </div>
          </div>

        </section>


        {/* Pagination */}
        <section className='pt-3 pb-md-5 pb-0'>
          <div className="container">

            <div className='d-flex justify-content-end me-0'>
              <ul className='pagination'>
                {
                  pageData.prev_page_url ? (
                    <li className='page-item'>
                      <button className='page-link border-danger fw-bold mt-3' onClick={handlePreviousPage}>
                        <span className='text-danger'>Previous</span>
                      </button>
                    </li>
                  ) : null
                }
                {
                  pageData.next_page_url ? (
                    <li className='page-item'>
                      <button className='page-link border-danger fw-bold mt-3' onClick={handleNextPage}>
                        <span className='text-danger'>Next</span>
                      </button>
                    </li>
                  ) : null
                }
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default MarketPlace